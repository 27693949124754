import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { PLANS, PLANS_ID } from '../../constants/plans'
import { upgradeAccount } from '../../services/manage'
import { downgradeSubscription } from '../../services/email-list'
import { ErrorBox } from 'components/ErrorBox/ErrorBox'
import PlanView from './components/PlanView'
import SuccessView from './components/SuccessView'
import { getCurrencyAndPrice, getSelectedPlanAndPrices } from 'constants/pricing/currency'
import { getErrorMessage } from 'utils/general'
import * as Sentry from '@sentry/react'
import { ERROR_CODE_MAP } from '../../stripe.constants'
import { getPayProCheckoutUrl } from 'services/manage'
import moment from 'moment'

export const CURRENT_PLAN = 'current_plan'
export const UPGRADE = 'upgrade_plan'
export const DOWNGRADE = 'downgrade_plan'

export const EmailSent = ({ history, onHide, userProfile }) => {
  const isStripeCustomer = `${userProfile?.customerId}`?.startsWith('cus_')
  return (
    <>
      {isStripeCustomer ? (
        <div>
          <div className="flex flex-column align-items-center">
            <div className="color-purple mb-2 font-22">Your account downgrade was successful</div>
            <img className="me-3 mb-2" height="25px" width="25px" alt="check" src="/check.png" />
            <div className="text-center mb-4 mt-2">
              <span>Your account will be downgraded</span>
              <br />
              <span>on your next billing cycle ({moment.unix(userProfile?.nextBilling).format('DD MMM YY')})</span>
              <br />
              <span className="text-center">We will notify you on the day of your downgrade</span>
            </div>
          </div>
          <Button
            className="float-right mt-1"
            onClick={() => {
              history.push('/'), onHide()
            }}
          >
            Done
          </Button>
        </div>
      ) : (
        <div>
          <div className="flex flex-column align-items-center">
            <div className="color-purple mb-2 font-22">Success</div>
            <img className="me-3 mb-2" height="25px" width="25px" alt="check" src="/check.png" />
            <span className="text-center">We’re processing your request and will email you when it’s complete.</span>
          </div>
          <Button
            className="float-right mt-1"
            onClick={() => {
              history.push('/'), onHide()
            }}
          >
            Done
          </Button>
        </div>
      )}
    </>
  )
}

const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAILED = 'FAILED'
const EMAIL_SENT = 'EMAIL_SENT'

const UpgradePayModal = ({
  history,
  email,
  show,
  onHide,
  currentPlanId = PLANS.PRO.id,
  periodProp = 'monthly',
  defaultPlan = PLANS.PRO_U.id,
  interval,
  currency,
  userProfile
}) => {
  const [stage, setStage] = useState(REQUEST)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const initialPlan = PLANS_ID[defaultPlan] || (currentPlanId === PLANS.TINY.id ? PLANS.PRO : PLANS.PRO_U)

  const [selectedPlan, setSelectedPlan] = useState(null)

  const onPlanChange = (id) => {
    const plan = PLANS_ID[id]
    setSelectedPlan(getSelectedPlanAndPrices(plan, getCurrencyAndPrice(currency)))
  }

  const handlePayProUpgradeCheckout = async (period, amount) => {
    const {
      data: { url }
    } = await getPayProCheckoutUrl({
      payProId: selectedPlan?.prices?.[period]?.payProId,
      email,
      upgradeAmount: amount
    })
    window.open(url, '_blank')
  }

  const urlParams = new URLSearchParams(window.location.search)
  const status = urlParams.get('status')

  useEffect(() => {
    if (show) {
      const { pathname } = window.location
      if (pathname === '/manage/subscription/upgrade') {
        history.replace({ pathname: '/manage/subscription/upgrade' })
        return
      }

      if (pathname === '/manage/subscription/change') {
        history.replace({ pathname: '/manage/subscription/change' })
        return
      }

      history.replace({ pathname: '/manage/subscription/change' })
    }
  }, [show, history])

  const onConfirm = ({ plan, period }) => {
    setIsLoading(true)
    upgradeAccount({
      email,
      plan,
      priceId: selectedPlan?.prices?.[period]?.stripePriceId,
      paddleId: selectedPlan?.prices?.[period]?.paddleId,
      payProId: selectedPlan?.prices?.[period]?.payProId,
      productId: selectedPlan?.id
    })
      .then(({ data }) => {
        if (data?.amount) {
          handlePayProUpgradeCheckout(period, data.amount)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setStage(SUCCESS)
        }
      })
      .catch((error) => {
        const declineCode = ERROR_CODE_MAP[error?.declineCode]
        const errorMessage =
          declineCode ||
          'Sorry, unable to change your plan. This is likely an issue with your card. Please contact your bank or our support.'
        setErrorMsg(errorMessage)

        if (!declineCode) {
          Sentry.captureException(error, {
            tags: {
              section: 'upgrade-account-error'
            }
          })
        }

        setIsLoading(false)
        setStage(FAILED)
      })
  }

  const onDowngradeSubscription = async (selectedPlan, planPrices = {}, period) => {
    const message = `${selectedPlan?.label} ${period}`
    setIsLoading(true)
    try {
      await downgradeSubscription({ email, planPrices, message })
      setStage(EMAIL_SENT)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setStage(FAILED)
      setErrorMsg(getErrorMessage(error))
    }
  }

  useEffect(() => {
    if (defaultPlan) {
      setSelectedPlan(getSelectedPlanAndPrices(initialPlan, getCurrencyAndPrice(currency)))
    }
  }, [defaultPlan, currency])

  useEffect(() => {
    if (status === 'success') {
      setStage(SUCCESS)
    }
  }, [status])

  const handleClose = () => {
    onHide()
    history.replace({ pathname: '/manage' })
    setSelectedPlan(null)
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus={false}
    >
      <div className="p-4">
        {stage === REQUEST && selectedPlan && (
          <PlanView
            isLoading={isLoading}
            currentPlanId={currentPlanId}
            defaultPlan={PLANS_ID[defaultPlan]}
            periodProp={periodProp}
            onConfirm={onConfirm}
            onDowngradeSubscription={onDowngradeSubscription}
            interval={interval}
            onPlanChange={onPlanChange}
            selectedPlan={selectedPlan}
          />
        )}
        {stage === SUCCESS && <SuccessView history={history} onHide={handleClose} />}
        {stage === EMAIL_SENT && <EmailSent history={history} onHide={handleClose} userProfile={userProfile} />}
        {stage === FAILED && (
          <ErrorBox
            message={errorMsg || 'Sorry, unable to change your plan'}
            onClick={() => {
              setStage(REQUEST)
              setErrorMsg(undefined)
            }}
          />
        )}
      </div>
    </Modal>
  )
}

export default UpgradePayModal
