import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import api from 'utils/api'
import { fetchUserData } from '../../Manage/actions'
import { useDispatch, useSelector } from 'react-redux'
import { API_ROOT } from '../../constants'
import { showUpgradeCardModal, setShowPaymentModal } from 'actions'
import { PLANS, ARCHIVE_LIMIT_FREE_PLAN } from 'constants/plans'

const ArchiveDeleteModal = ({ show, message, domain, onClose, title }) => {
  const [loadingAction, setLoadingAction] = useState(null)
  const dispatch = useDispatch()

  const { userProfile, archivedSites } = useSelector((state) => state.manage)

  const isFreeUser = !userProfile.productId
  const archivedCount = archivedSites.length

  const handleAction = async (action) => {
    if (action === 'ARCHIVE' && isFreeUser && archivedCount >= ARCHIVE_LIMIT_FREE_PLAN) {
      dispatch(
        showUpgradeCardModal({
          title: 'Upgrade for unlimited archived files',
          desc: 'On the free plan, you can archive up to 3 projects. Our paid plans allow unlimited archives.',
          cta: 'Upgrade now',
          onClick: () => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
      onClose()
      return
    }
    setLoadingAction(action)
    try {
      await api.post(`${API_ROOT}/pro/${action.toLowerCase()}`, {
        domain: domain?.subdomain || domain?.link,
        site: domain
      })
      dispatch(fetchUserData(false))
      setLoadingAction(false)
      onClose()
    } catch (err) {
      setLoadingAction(false)
    }
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onClose}
      animation={false}
      centered
      enforceFocus={false}
    >
      <Modal.Header className="p-3 pb-2 border-0 justify-start">
        <Modal.Title className="bold color-purple-light text-center">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-24px font-18" dangerouslySetInnerHTML={{ __html: message }} />
      <Modal.Footer className="border-0">
        <Button variant="outline-primary" onClick={() => handleAction('ARCHIVE')} disabled={loadingAction}>
          {loadingAction === 'ARCHIVE' ? <Spinner as="span" animation="border" size="sm" /> : 'Archive'}
        </Button>
        <Button variant="primary" onClick={() => handleAction('DELETE')} disabled={loadingAction}>
          {loadingAction === 'DELETE' ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArchiveDeleteModal
