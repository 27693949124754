import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import {
  DISPLAY_MODE_STANDARD,
  GATED_MODE_EMAIL_GATE,
  GATED_MODE_PASSWORD,
  GATED_MODE_STANDARD,
  LABEL_MAP,
  ACTION_UPDATE
} from '../interface'
import DomainForm from './components/DomainForm'
import FileSelectionForm from './components/FileSelectionForm'
import ToggleButtonsForm from './components/ToggleButtonsForm'
import { getDomainSuffix } from 'utils/general'
import { PRO_MAX_FILE_SIZES } from 'constants/plans'
import { getFileType } from 'helpers/file-type'

const CreateUpdateSiteForm = ({ formValues, onSubmit, validate }) => {
  const { action, userProfile, site, files, customDomains, tlds, disableProFeatures, draggedFile, currentFormValues } =
    formValues
  const { productId } = userProfile

  const {
    subdomain = '',
    passwordProtected = false,
    password = '',
    fileUploadLimit = PRO_MAX_FILE_SIZES[productId],
    disableIndexing = false,
    disableExportPdf = false,
    siteConfiguration = false,
    displayMode = DISPLAY_MODE_STANDARD,
    enableEmailGate = false,
    feedbackMode = false,
    filename = '' /** v1 */,
    linkPreview
  } = site || {}

  const [domainError, setDomainError] = useState(null)

  const updatingPhpFile = site?.type.includes('php') && action === ACTION_UPDATE

  const domainFormValues = {
    domain: subdomain,
    subdomain: subdomain?.split('.')[0] || '',
    domainSuffix: getDomainSuffix(subdomain).tld || tlds[0],
    customDomains: customDomains,
    tlds
  }

  const uploadFormValues = {
    selectedFile: files?.[0],
    draggedFile: draggedFile,
    addedFiles: files?.length >= 1 ? files.slice(1) : [],
    fileUploadLimit,
    progress: 0,
    indexFile: files.find((file) => file.isIndex === true || file.name === 'index.html')?.name,
    fileType: getFileType(files?.[0]?.fileName || filename || draggedFile?.name),
    isZipPhpFile: false
  }

  const toggleFormValues = {
    displayMode: displayMode,
    feedbackMode: feedbackMode,
    gatedMode: enableEmailGate ? GATED_MODE_EMAIL_GATE : passwordProtected ? GATED_MODE_PASSWORD : GATED_MODE_STANDARD,
    passwordProtected,
    password: enableEmailGate ? '' : password,
    disableIndexing: disableIndexing === 'true',
    disableExportPdf,
    siteConfiguration,
    errorPath: siteConfiguration ? JSON.parse(siteConfiguration).errorPath : '/'
  }

  const initialValues = {
    domainValues: domainFormValues,
    uploadValues: uploadFormValues,
    toggleValues: toggleFormValues
  }

  const validateForm = (values) => {
    const errors = validate(values)
    if (domainError) {
      errors.domainValues = errors.domainValues || {}
      errors.domainValues.subdomain = domainError
    }
    return errors
  }

  return (
    <Formik
      initialValues={currentFormValues || initialValues}
      onSubmit={(values) => onSubmit(values, initialValues)}
      validate={validateForm}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({ values: { domainValues, uploadValues, toggleValues }, setFieldValue, isValid, errors }) => {
        return (
          <Form>
            {!linkPreview && (
              <DomainForm
                name={'domainValues'}
                initialValues={domainValues}
                userProfile={userProfile}
                action={action}
                disableProFeatures={disableProFeatures}
                setFieldValue={setFieldValue}
                uploadValues={uploadValues}
                updatingPhpFile={updatingPhpFile}
                setDomainError={setDomainError}
                domainError={domainError}
                disabled={feedbackMode}
              />
            )}

            <FileSelectionForm
              name={'uploadValues'}
              initialValues={uploadValues}
              domainValues={domainValues}
              userProfile={userProfile}
              setFieldValue={setFieldValue}
            />

            {!updatingPhpFile && (
              <ToggleButtonsForm
                name={'toggleValues'}
                initialValues={toggleValues}
                userProfile={userProfile}
                uploadValues={uploadValues}
                setFieldValue={setFieldValue}
              />
            )}

            <Button className="action-btn" type="submit" disabled={domainError !== null}>
              {LABEL_MAP[action]}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateUpdateSiteForm
