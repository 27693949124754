import React from 'react'
import { Button } from 'react-bootstrap'
import { formatNumber } from '../../../utils/general'
import { ALL_FEATURES } from '../../../constants/plans'

const InitialStage = ({ visitorCount, onHide, onContinueToCancellation, currentPlanId }) => {
  const lostFeatures = ALL_FEATURES.filter((feature) => feature.enabled.includes(currentPlanId))

  return (
    <div>
      <h1 className="modalTitle text-center mt-2">Are you sure you want to cancel?</h1>
      {visitorCount > 0 && (
        <div className="mb-4 text-center grey-background box-100-2 p-3 mt-4 round-8">
          <div className="text-muted">You've had</div>
          <div className="font-38 bold">{formatNumber(visitorCount)} visits</div>
          <div className="text-muted">Across all your projects this month</div>
        </div>
      )}
      <div className="mb-4 m-auto" style={{ maxWidth: 400 }}>
        <div className="mb-3">
          Your projects <b>will be deleted</b> when your subscription ends & you'll also lose access to:
        </div>
        <ul className="list-unstyled">
          {lostFeatures.map((feature, index) => (
            <li key={index} className="d-flex ms-5 mb-2">
              <span className="me-2">
                <img src="/assets/icons/x.png" alt="Lost feature" width="15" height="15" />
              </span>
              <span>{feature.label}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-5 text-end flex">
        <Button variant="outline-primary" onClick={onHide} className="me-2 flex-1">
          Keep subscription
        </Button>
        <Button variant="danger" onClick={onContinueToCancellation} className="flex-1">
          Continue to cancellation
        </Button>
      </div>
    </div>
  )
}

export default InitialStage
