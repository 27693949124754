import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { ToolTip } from '../ProPlans/PlanCards'
import { formatNumber } from '../../utils/general'
import { PLANS } from '../../constants/plans'

function getFormattedPlanPrice(plan, period) {
  const details = period === 'yearly' ? plan?.yearly : plan?.monthly
  const currencySymbol = details?.currency || '$' // Fallback to dollar if no currency is provided
  const value = details?.value || 0

  return {
    currency: currencySymbol,
    value: value,
    formattedPrice: `${currencySymbol}${value / (period === 'yearly' ? 12 : 1)}/mo`
  }
}

function PricingTable({ plans, prices, period, featureList }) {
  const freePlanDetails = getFormattedPlanPrice(prices.freePlan, period)
  const tinyPlanDetails = getFormattedPlanPrice(prices.tinyPlan, period)
  const soloPlanDetails = getFormattedPlanPrice(prices.soloPlan, period)
  const proPlanDetails = getFormattedPlanPrice(prices.proPlan, period)

  return (
    <>
      <Table className="mt-5" responsive hover id="pricing-table">
        <thead>
          <tr>
            <th className="font-20 bold text-start d-none d-sm-block">
              Plans <span className="d-block">&nbsp;</span>
            </th>
            <th className="font-20 bold text-start">
              <span>Free</span>
              <span className="d-block">{freePlanDetails.formattedPrice}</span>
            </th>
            <th className="text-blue font-20 bold text-start">
              Tiny <span className="d-block">{tinyPlanDetails.formattedPrice}</span>
            </th>
            <th className="text-purple font-20 bold text-start">
              Solo <span className="d-block">{soloPlanDetails.formattedPrice}</span>
            </th>
            <th className="text-warning font-20 bold text-start">
              Pro <span className="d-block">{proPlanDetails.formattedPrice}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <>
            <tr className="d-block d-sm-none w-100 border border-0">
              <td className="text-start bold font-18 border border-0" colSpan={4}>
                Active Sites
              </td>
            </tr>
            <tr>
              <td className="text-start bold font-18 d-none d-sm-block">
                Active Projects{' '}
                <ToolTip text="abc.tiiny.site is one active project, multiple files can be uploaded on one active project" />
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/free.svg`}
                />
                <span className="d-inline-block font-18">{plans.FREE.projectLimit}</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/tiny.svg`}
                />
                <span className="d-inline-block font-18">{plans.TINY.projectLimit}</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/solo.svg`}
                />
                <span className="d-inline-block font-18">{plans.PRO.projectLimit}</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/pro.svg`}
                />
                <span className="d-inline-block font-18">Unlimited</span>
              </td>
            </tr>
          </>
          <>
            <tr className="d-block d-sm-none w-100 border border-0">
              <td className="text-start bold font-18 d-block d-sm-none w-100 border border-0" colSpan={4}>
                File Uploads
              </td>
            </tr>
            <tr>
              <td className="text-start bold font-18 d-none d-sm-block">
                File Uploads <ToolTip text="Limit per file upload, per project" />
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/free.svg`}
                />
                <span className="d-inline-block font-18">{plans.FREE.fileSizeLimit} mb</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/tiny.svg`}
                />
                <span className="d-inline-block font-18">{plans.TINY.fileSizeLimit} mb</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/solo.svg`}
                />
                <span className="d-inline-block font-18">{plans.PRO.fileSizeLimit} mb</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/pro.svg`}
                />
                <span className="d-inline-block font-18">{plans.PRO_U.fileSizeLimit} mb</span>
              </td>
            </tr>
          </>
          <>
            <tr className="d-block d-sm-none w-100 border border-0">
              <td className="text-start bold font-18 d-block d-sm-none w-100 border border-0" colSpan={4}>
                Visitors <span className="font-small">/mo</span>
              </td>
            </tr>
            <tr>
              <td className="text-start bold font-18 d-none d-sm-block">
                Visitors <span className="font-small">/mo</span>
                <ToolTip text="Total views limit across all projects per month" />
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/free.svg`}
                />
                <span className="d-inline-block font-18">{formatNumber(plans.FREE.visitorLimit)}</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/tiny.svg`}
                />
                <span className="d-inline-block font-18">{formatNumber(plans.TINY.visitorLimit)}</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/solo.svg`}
                />
                <span className="d-inline-block font-18">{formatNumber(plans.PRO.visitorLimit)}</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src={`/assets/icons/pro.svg`}
                />
                <span className="d-inline-block font-18">{formatNumber(plans.PRO_U.visitorLimit)}</span>
              </td>
            </tr>
          </>
          <>
            <tr className="d-block d-sm-none w-100 border border-0">
              <td className="text-start bold font-18 d-block d-sm-none w-100 border border-0" colSpan={4}>
                Bandwidth <span className="font-small">/mo</span>
              </td>
            </tr>
            <tr>
              <td className="text-start bold font-18 d-none d-sm-block">
                Bandwidth <span className="font-small">/mo</span>
                <ToolTip text="Total views limit across all projects per month" />
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src="/assets/icons/free.svg"
                />
                <span className="d-inline-block font-18">{plans.FREE.bandwidthLimit} GB</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src="/assets/icons/tiny.svg"
                />
                <span className="d-inline-block font-18">{plans.TINY.bandwidthLimit} GB</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src="/assets/icons/solo.svg"
                />
                <span className="d-inline-block font-18">{plans.PRO.bandwidthLimit} GB</span>
              </td>
              <td className="text-start">
                <img
                  className="d-inline-block me-2"
                  height="22px"
                  width="22px"
                  alt="check"
                  src="/assets/icons/pro.svg"
                />
                <span className="d-inline-block font-18 ms-2">{plans.PRO_U.bandwidthLimit} GB</span>
              </td>
            </tr>
          </>
          {featureList.map(({ label, tooltip, enabled }, index) => (
            <React.Fragment key={label}>
              <tr className="d-block d-sm-none w-100 border border-0">
                <td className="text-start bold font-18 d-block d-sm-none border border-0" colSpan={4}>
                  {label}
                </td>
              </tr>
              <tr key={index}>
                <td className="text-start bold font-18 d-none d-sm-block">
                  {label} <ToolTip text={tooltip} />
                </td>
                <td className="text-start">
                  {enabled.includes(PLANS.FREE.id) && (
                    <img className="d-block" height="22px" width="22px" alt="check" src="/assets/icons/free.svg" />
                  )}
                </td>
                <td className="text-start">
                  {enabled.includes(PLANS.TINY.id) && (
                    <img className="d-block" height="22px" width="22px" alt="check" src="/assets/icons/tiny.svg" />
                  )}
                </td>
                <td className="text-start">
                  {enabled.includes(PLANS.PRO.id) && (
                    <img className="d-block" height="22px" width="22px" alt="check" src="/assets/icons/solo.svg" />
                  )}
                </td>
                <td className="text-start">
                  {enabled && (
                    <img className="d-block" height="22px" width="22px" alt="check" src="/assets/icons/pro.svg" />
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <Button
        variant="outline-secondary"
        style={{ width: 150 }}
        onClick={() => window.$crisp.push(['do', 'chat:open'])}
      >
        Contact us
      </Button>
    </>
  )
}

export default PricingTable
