import React from 'react'
import { Button } from 'react-bootstrap'

import { QUARTERLY } from '../../../constants/plans/pro-student'
import CustomTooltip from '../../CustomTooltip'
import { PERIOD_YEAR } from '../../../constants/plans'
import { formatNumber, formatStorage, pluralize } from '../../../utils/general'

import styles from './PlanCard.module.css'

const getPrice = (period, planPrice) => (period === 'yearly' ? planPrice?.yearly.value / 12 : planPrice?.monthly?.value)

export const ToolTip = ({ children, text }) => (
  <CustomTooltip label={text} placement="right">
    {children}
  </CustomTooltip>
)

const PlanCard = ({
  position,
  plan,
  planPrice,
  title,
  subtitle,
  period,
  features,
  ctaTheme,
  onSubPlanClick,
  popular,
  hideCompareCTA
}) => {
  const price = period === 'yearly' ? planPrice?.yearly?.value : planPrice?.monthly?.value
  const currency = period === 'yearly' ? planPrice?.yearly?.currency : planPrice?.monthly?.currency

  const scrollToPricingTable = () => {
    document.getElementById('pricing-table').scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className={`${styles.planCard} ${popular ? styles.popularBg : ''}`}>
      {popular && <div className={styles.popularBadge}>Most Popular</div>}
      <div className={`${styles[position]} ${styles.container} flip-in-ver-left ${popular ? styles.popular : ''}`}>
        <div>
          <div className={`${styles.headerContainer}`}>
            <h2 className={`font-26 font-weight-900 text-${ctaTheme} bold mt-3`}>{title}</h2>
            <div className={`grey mb-3 text-start ${styles.subtitle}`}>{subtitle}</div>
            <div className={`${styles.price}`}>
              {price === 0 ? (
                <div>
                  <div className="flex">
                    <span className="font-26 font-weight-900 grey bold">
                      {currency}
                      <b className="font-47 bold">{formatNumber(getPrice(period, planPrice))}</b>

                      <span className={`${styles.period} black ms-2`}>
                        {' '}
                        / {period === QUARTERLY ? '3-months' : 'month'}
                      </span>
                    </span>
                  </div>
                  {period === PERIOD_YEAR && (
                    <div className="font-small text-start color-#374151">Upgrade any time</div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="flex">
                    <span className={`font-26 font-weight-900 text-${ctaTheme} bold`}>
                      {currency}
                      <b className={`font-47 text-${ctaTheme} bold`}>{formatNumber(getPrice(period, planPrice))}</b>
                      <span className={`${styles.period} black ms-2`}>
                        / {period === QUARTERLY ? '3-months' : 'month'}
                      </span>
                    </span>
                  </div>
                  {period === PERIOD_YEAR && (
                    <div className="font-14 grey text-start" style={{ color: '#374151' }}>
                      Billed {currency}
                      {price} /year
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              <Button
                className={`mt-3 ${styles.ctaButton}`}
                variant={ctaTheme}
                onClick={() => onSubPlanClick(plan?.id)}
              >
                Get started <span className={styles.arrow}>&rarr;</span>
              </Button>
              <div className={styles.dollar}>
                <p className="ms-1 mb-0">🎖️ 7 day money back guarantee</p>
              </div>
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <div>
            <div className={`flex pb-1`}>
              <img
                height="20px"
                width="20px"
                alt="project"
                src={`/assets/icons/tv.svg`}
                className={styles[`icon-${ctaTheme}`]}
              />
              <ToolTip
                text={`${plan.label === 'Pro' ? 'Total account limit is 10GB' : 'abc.tiiny.site is one active project, multiple files can be uploaded on one active project'}`}
              >
                <div className={`dark-grey bold text-start ${styles.label}`}>
                  {plan.projectLimit === 999 ? 'Unlimited' : plan.projectLimit} active{' '}
                  {pluralize('project', 'projects', plan.projectLimit)}
                </div>
              </ToolTip>
            </div>
            <div className="flex mt-2">
              <img
                height="20px"
                width="20px"
                alt="upload"
                src={`/assets/icons/cloud-arrow-up-upload.svg`}
                className={styles[`icon-${ctaTheme}`]}
              />
              <ToolTip
                text={
                  plan.projectLimit === 999 ? 'Total upload limit for account' : 'Limit per file upload, per project'
                }
              >
                <div className={`dark-grey bold text-start ${styles.label}`}>
                  {plan.projectLimit === 999 && `${formatStorage(plan.accountLimit)} account limit`}
                  {plan.projectLimit !== 999 && `${formatStorage(plan.fileSizeLimit)} upload limit/project`}
                  <br />
                  {/* {plan?.label === 'Free' && <span className="font-small fw-normal">(0.5mb PDF limit)</span>} */}
                </div>
              </ToolTip>
            </div>
            <div className="flex mt-2">
              <img
                height="20px"
                width="20px"
                alt="visitors"
                src={`/assets/icons/chart-bar-square.svg`}
                className={styles[`icon-${ctaTheme}`]}
              />
              <ToolTip text="Total views limit across all projects per month">
                <div className={`bold dark-grey text-start ${styles.label}`}>
                  {formatNumber(plan.visitorLimit)} visitors <span className="font-small">/mo</span>
                </div>
              </ToolTip>
            </div>
          </div>

          <div className="mt-4">
            {plan?.label !== 'Free' && <h2 className="font-16 text-start mb-3">Additional Features:</h2>}
            {features.map(({ label, tooltip, icon, checkicon }, index) => (
              <div className="mt-2" key={index}>
                <ToolTip text={tooltip}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={`d-flex justify-center align-center`}>
                      <img height="20px" width="20px" alt="icon" src={`/assets/icons/${icon}`} />
                      <div className={`dark-grey text-start ${styles.label}`}>{label}</div>
                    </div>
                    <img height="20px" width="20px" alt="check" src={`/assets/icons/${checkicon}`} />
                  </div>
                </ToolTip>
              </div>
            ))}
          </div>
        </div>

        {!hideCompareCTA && (
          <div>
            <Button variant="outline-secondary" className="w-100 font-14" onClick={scrollToPricingTable}>
              Compare plans <span className={styles.arrow}>&darr;</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PlanCard
