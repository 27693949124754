import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, FormControl } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { withRouter } from 'react-router'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { connect, useDispatch } from 'react-redux'

import { API_ROOT, REGEX_EMAIL, ERROR_MSGS, errors } from '../../constants'
import { STAGE_SUCCESS } from '../../Landing/Home/Home'
import { trackUpload } from '../../utils/analytics'
import { PLANS, PLANS_ID } from '../../constants/plans'
import {
  setDraggedFile,
  setPreviewData,
  setPreviewModal,
  setQrCodeData,
  setQrCodeModal,
  showCreateSiteModal,
  toggleModal
} from 'Manage/actions'
import ShareBox from './components/ShareBox'
import { getDirectFileLink } from '../../utils/general'
import { STATE_READY } from '../CreateUpdateSiteModal/interface'
import { setShowPaymentModal, showUpgradeCardModal, UPGRADE_QR_PAYLOAD } from '../../actions'
import SocialLogin from 'components/SocialLogin/SocialLogin'
import { useHistory } from 'react-router-dom'

import './SuccessBox.css'

const SuccessBoxHomePage = (props) => {
  const [email, setEmail] = useState(props.recordedEmail || '')
  const [domainOwned, setDomainOwned] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [notOwner, setNotOwner] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [authToken, setAuthToken] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const { uploaded: { type, size } = {}, userProfile } = props
    trackUpload(PLANS_ID[userProfile?.productId]?.label || 'Free', size, type, userProfile?.email)
    dispatch(setDraggedFile(null))
    dispatch(setQrCodeData(props.domain))

    // Listen for the OAuth callback message
    window.addEventListener('message', onMessageEvent)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('message', onMessageEvent)
      dispatch(setDraggedFile(null))
      dispatch(setQrCodeData(null))
    }
  }, [])

  useEffect(() => {
    if (authToken) {
      onKeep()
    }
  }, [authToken])

  const onMessageEvent = (event) => {
    if (event.data.type === 'oauthCallback') {
      if (event.data.newAccount) {
        setAuthToken(event.data.token)
      } else {
        dispatch(
          showUpgradeCardModal({
            showLoading: false,
            showError: true,
            errorMessage: ERROR_MSGS[errors.USER_EXISTS]
          })
        )
      }
    }
  }

  const onKeep = async () => {
    let payload = {}

    const isValid =
      authToken || (REGEX_EMAIL.test(email.toLowerCase()) && email.toLowerCase().search('mailinator') === -1)
    if (!isValid) {
      setInvalidEmail(true)
      return
    } else {
      setInvalidEmail(false)
    }

    setIsFetching(true)

    const recaptchaValue = await props.googleReCaptchaProps.executeRecaptcha('homepage')
    const headers = {
      'x-recaptcha': recaptchaValue
    }

    if (authToken) {
      payload = {
        subdomain: props?.domain?.split('.')[0],
        tld: `.${props?.domain?.split('.')?.slice(1)?.join('.')}`,
        authToken
      }
    } else {
      payload = {
        subdomain: props?.domain?.split('.')[0],
        tld: `.${props?.domain?.split('.')?.slice(1)?.join('.')}`,
        email
      }
    }

    axios
      .post(`${API_ROOT}/create-trial-account`, payload, {
        headers: {
          'Content-Type': 'application/json',
          ...headers
        }
      })
      .then((res) => {
        setIsFetching(false)
        if (res.data.success) {
          setDomainOwned(res.data.success)
          if (authToken) {
            history.push(`/free-welcome?id=${res.data.token}&trial=true`)
            return // Ensure no further execution
          }
          props.onFieldChange('stage', STAGE_SUCCESS)
        }
      })
      .catch((err) => {
        setIsFetching(false)
        setNotOwner(err.response.data.code === errors.NOT_OWNER)
      })
  }

  const getExpiryLabel = (expiry, neverExpires) => {
    if (neverExpires) {
      return 'is live'
    } else if (expiry) {
      return `is live for the next ${expiry} days`
    } else {
      return 'is live for 15 mins'
    }
  }

  const onQRCode = () => {
    const { dispatch, userProfile, domain } = props

    if (userProfile.productId) {
      dispatch(setQrCodeModal(true))
      dispatch(setQrCodeData(domain))
    } else {
      dispatch(
        showUpgradeCardModal({
          ...UPGRADE_QR_PAYLOAD,
          onClick: () => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
    }
  }

  const onClose = () => {
    dispatch(showCreateSiteModal(false))
    dispatch(toggleModal(false))

    if (props.onClose) props.onClose()
  }

  const onUpdateSite = () => {
    props.setRootState({
      stage: STATE_READY,
      uploaded: undefined
    })
  }

  const onViewSite = (domain) => {
    if (domain) window.open(`https://${domain}`, '_blank')
    else {
      dispatch(setPreviewModal(true))
      dispatch(setPreviewData(domain))
    }
  }

  const openOAuthWindow = (provider) => window.open(`${API_ROOT}/oauth/${provider}?behavior=modal`, '_blank')

  const {
    domain,
    showUpdateSuccessView,
    hideUpgradeView,
    uploaded,
    userProfile,
    expiry,
    neverExpires,
    showUpdateButton
  } = props

  return (
    <div className="success-box">
      <div onClick={onClose}>
        <img className="exit" src="delete.png" alt="quit" />
      </div>
      <h3>Claim your free site</h3>
      <img className="celebrate" src="celebrate.gif" alt="celebrate" />
      <div>
        {domain ? (
          <h5 className="domain">
            <a href={`https://${domain}`} target="_blank" rel="noreferrer">
              {domain}
            </a>
          </h5>
        ) : (
          <h5>Your Site</h5>
        )}
        <h6>
          <p>{getExpiryLabel(expiry, neverExpires)}</p>
        </h6>
        <div className="d-flex flex-column mt-2 mb-3 align-items-center">
          <div className="mt-2 d-flex align-items-center flex-row">
            {showUpdateButton && (
              <Button className="me-2" variant="outline-primary" onClick={onUpdateSite}>
                Update
              </Button>
            )}
            <Button onClick={() => onViewSite(domain)}>View Site</Button>
          </div>
          {showUpdateButton && domain && (
            <>
              <ShareBox
                passwordProtected={uploaded.passwordProtected}
                trial={!!!userProfile.productId}
                url={`https://${domain}`}
                directFileUrl={getDirectFileLink(
                  `https://${domain}`,
                  uploaded.filename,
                  'pdf',
                  uploaded.lastUpdated || uploaded.created
                )}
                onQRClick={onQRCode}
                type={uploaded.type}
              />
              <div className="d-flex align-items-center justify-items-center mt-3 gap-1 font-small">
                <span className="d-inline">Something broken? -</span>
                <a
                  className="d-inline link"
                  href="https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read our help guide
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      {!hideUpgradeView && (
        <>
          {isFetching ? (
            <Spinner className="mt-5" animation="border" />
          ) : (
            <div className="container-lock-site">
              {notOwner && <div>Sorry, that link name is taken</div>}
              <div>
                {!domainOwned && !showUpdateSuccessView && (
                  <>
                    <div className="mb-3 mt-2">
                      <div className="bold font-22 color-purple-light">Keep your site Live</div>
                      <span className="light-grey font-16">Sign up for a free account</span>
                    </div>
                    <SocialLogin onClick={openOAuthWindow} className="height-50" disabled={errors.subdomain} />
                    <div className="separator mt-3 w-50 ms-auto me-auto">Or use a different email</div>
                    <div className="flex">
                      <div className="flex-2">
                        <FormControl
                          className="mt-3 tr-landing-email-input"
                          name="email"
                          type="text"
                          placeholder="Email"
                          aria-label="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled={props.disabled}
                        />
                        {invalidEmail && <div className="validation-error">Please enter a valid email</div>}
                      </div>
                      <Button
                        type="submit"
                        className="flex-1 ms-2 mt-3 tr-landing-launch-site"
                        variant="outline-primary"
                        onClick={onKeep}
                      >
                        Continue
                      </Button>
                    </div>
                  </>
                )}
                {domainOwned && (
                  <div className="mt-3">
                    <img height="20px" alt="check" src="check.png" />
                    <b> We've emailed you a login link</b>
                    <div className="link" onClick={() => setDomainOwned(false)}>
                      Try again
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ manage }) => ({
  userProfile: manage.userProfile
})

export default connect(mapStateToProps)(withRouter(withGoogleReCaptcha(SuccessBoxHomePage)))
