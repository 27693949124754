import axs from 'axios'
import * as Sentry from '@sentry/react'

export const baseURL = process.env.API_URL

const axios = axs.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

const handleAxiosError = (err, url, throwErrResponse = false) => {
  const errorDetails = {
    url: url,
    message: err.message,
    stack: err.stack,
    code: err.code,
    request: err.request && {
      path: err.request.path,
      method: err.request.method,
      headers: err.request.headers
    },
    response: err.response && {
      status: err.response.status,
      statusText: err.response.statusText,
      headers: err.response.headers
    }
  }
  Sentry.captureException(errorDetails, {
    tags: {
      section: 'axios-error'
    }
  })

  if (throwErrResponse && err.response) {
    throw err.response.data
  }
  throw err
}

export default {
  async get(url, params, headers, config) {
    try {
      return await axios.get(url, {
        params,
        headers: {
          ...headers,
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        ...config
      })
    } catch (err) {
      handleAxiosError(err, url)
    }
  },
  async post(url, params, headers, uploadProgress, throwErrResponse = true) {
    try {
      return await axios.post(url, params, {
        headers: {
          ...headers,
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: uploadProgress
      })
    } catch (err) {
      handleAxiosError(err, url, throwErrResponse)
    }
  },

  async put(url, params, headers, uploadProgress) {
    try {
      return await axios.put(url, params, {
        headers: {
          ...headers,
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: uploadProgress
      })
    } catch (err) {
      handleAxiosError(err, url)
    }
  }

  /*  async put(url, params, headers) {
      try {
        const response = await axios.put(url, params, { headers: { ...headers } });
        return response;
      } catch (err) {
        throw err;
      }
    },
    async patch(url, params, headers) {
      try {
        const response = await axios.patch(url, params, { headers: { ...headers } });
        return response;
      } catch (err) {
        throw err;
      }
    },
    async delete(url, params, headers) {
      try {
        return await axios.delete(url, { params, headers: { ...headers } });
      } catch (err) {
        throw err;
      }
    },
    axios,*/
}
